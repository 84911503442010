.kpiCard {
    flex: 1;
    min-width: 300px;
}

.cardBody {
    color: #000000DE !important;
    max-width: 100% !important;
}

.statisticSubheader {
    align-items: center;
    max-width: 100%;
    height: 16px;
    
    .pointDivider {
        padding: 0 4px 8px 4px;
        position: relative;
        top: -3px;
    }
}

.ellipsisText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
} 