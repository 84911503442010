.filtersContainer {
  margin: 16px 0 0 0;
  padding: 16px;
  background: #f9fafb;
  border-radius: 4px;
  position: relative;
}

.filtersHeader {
  margin: 0px;
}

.filtersCloseIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.filtersDropdowns {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
