.modalBodyContainer {
  min-width: 440px;
  margin: 2px;
}

.actionButtons {
  display: flex;
  gap: var(--tds-spacing-element-16);
}

.numberFieldsContainer {
  display: flex;
  padding: 0;
  justify-content: space-between;
  min-width: 440px;
}

.numberField {
  display: flex;
  max-width: 100px;
  overflow: hidden;
}

.numericFielError {
  color: var(--tds-text-field-helper-error);
  font: var(--tds-detail-05);
  letter-spacing: var(--tds-detail-05-ls);
  max-width: 120px;
}
