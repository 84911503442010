.category_groups_container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border: 0;
}

.single_group_container {
  width: 357px;
  margin-right: 12px;
  margin-bottom: 4px;
}

.single_group_container:last-of-type {
  margin-right: 0;
}

.container_header {
  display: flex;
  align-items: flex-start;
  gap: 8px;

  tds-icon {
    margin-top: 4px;
  }
}

.group_params_units_container {
  margin-left: 48px;
  margin-top: 4px;
}

.single_unit {
  margin-bottom: 4px;
}