.firstRowHeaderCell {
  padding: 0px 16px;
  margin: 0px;
  font: var(--tds-headline-07);
  letter-spacing: var(--tds-headline-07-ls);
  display: table-cell;
  color: var(--tds-table-color);
  background-color: var(--tds-table-header-background);
  border-bottom: 1px solid var(--tds-table-header-background-hover);
  border-right: 1px solid var(--tds-table-header-background-hover);
  height: 48px;
  min-width: 192px;
  box-sizing: border-box;
  vertical-align: middle;
  overflow: hidden;
  transition: background-color 200ms ease 0s;
}

.firstRowHeaderCell:first-of-type {
  border-top-left-radius: 4px;
  padding-left: 46px;

  &.noLeftPadding {
    padding-left: 16px;
    border-top-left-radius: 0px;
  }
}

.firstRowHeaderCell:last-of-type {
  border-top-right-radius: 4px;
  border-right: 0px;
}

.stickyTopHeaderParam {
  position: sticky;
  top: 0;
  z-index: 10;
}

.stickyTopUnit {
  position: sticky;
  top: 48px;
  z-index: 10;
}

.firstColumnZIndex {
  z-index: 20;
}

.leftAlignedText {
  text-align: left;
}

.stickyLeft {
  min-width: 192px;
  position: sticky;
  left: 0;
}

.subTh {
  background-color: var(--tds-grey-100);;
  font-weight: 400 !important;
  font-size: 12px !important;
  min-width: 124px !important;
  cursor: pointer;
  height: 32px !important;
  padding: 0px var(--tds-spacing-element-16);
  border-right: 1px solid var(--tds-table-divider);
  border-bottom: 1px solid var(--tds-table-divider);

  .thContent {
    min-width: max-content;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &.reorderableUnit {
    padding-top: 4px;
  }
}

.subTh:first-of-type {
  padding-left: 46px;
  .thContent {
    justify-content: flex-start;
  }
}

.subTh:last-of-type {
  border-right: none;
}

.normalColWidth {
  min-width: 124px !important;
  max-width: 125px !important;
}

.reorderableCol {
  max-width: 256px !important;
}

.iconsContainer {
  margin-left: 8px;
  margin-right: 8px;
  align-self: center;
}

.hoverable {  
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.beforeIcons {
  visibility: hidden;
  margin-right: 8px;
  margin-left: 8px;
  align-self: center;
}

.hoverable:hover {
  background-color: var(--tds-grey-200) !important;
  cursor: pointer;
}

.hoverable:hover .beforeIcons {
  visibility: visible;
}

.sortedColumn {
  background-color: var(--tds-grey-200) !important;
}

.sortedColumn:hover {
  background-color: var(--tds-grey-200) !important;
}

.sortedGroupHeader {
  border-bottom: 2px solid var(--tds-grey-500) !important;
}

.overflowTh {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hoveredSubth {
  background-color: var(--tds-grey-200) !important;
}

.hoveredSubth .beforeIcons {
  visibility: visible;
}

.headerTooltips {
  display: none;
}

.hoverableHeader:hover {
  background-color: var(--tds-grey-400) !important;
}

.hoverableHeader:hover .headerTooltips {
  display: block;
}