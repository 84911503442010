.toastContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: var(--tds-spacing-element-8);
  position: fixed;
  right: var(--tds-spacing-element-32);
  bottom: var(--tds-spacing-element-32);
  z-index: 9999;

  .tdsToast {
    position: initial;
    animation: slideInOut 5s ease-in-out forwards;
  }
}

@keyframes slideInOut {
  0% {
    transform: translateX(100%);
  }

  10%,
  90% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}