.shepherd-element.shepherd-has-title[data-popper-placement^='bottom'] {
    transform: translateY(20px);

    &>.shepherd-arrow:before {
        background-color: #fff;
    }
}

.shepherd-element.shepherd-has-title[data-popper-placement^='right'] {
    transform: translateX(20px);

    &>.shepherd-arrow:before {
        background-color: #fff;
    }
}

.shepherd-element.shepherd-has-title[data-popper-placement^='top'] {
    transform: translateY(-20px);

    &>.shepherd-arrow:before {
        background-color: #fff;
    }
}

.vp-shepherd-tour {
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.25);
    max-width: 400px;
}

.vp-tour-intro {
    max-width: 700px;
}

.vp-intro {
    max-width: 500px;
}

.vp-shepherd-tour .shepherd-content .shepherd-header {
    background-color: #fff;
    display: flex;
    padding: 16px 16px 0 16px;
}

.vp-tour-intro .shepherd-header {
    height: 140px;
    background-color: var(--scope-grey-300);
    background-image: url('../assets/tourImg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    padding: 16px;
}

.vp-tour-intro .shepherd-header img {
    width: 60%;
    margin: auto;
    position: absolute;
    bottom: 0;
    left: 20%;
}

.vp-tour-intro h3 {
    font: var(--tds-headline-05);
    width: 100%;
}

.vp-tour-intro .shepherd-cancel-icon {
    align-self: flex-start;
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
    color: hsla(0, 0%, 50%, .75);
    font-size: 2em;
    font-weight: 400;
}

.vp-shepherd-tour .shepherd-content .shepherd-header .shepherd-title {
    width: 100%;
    color: var(--tds-grey-958);
    text-align: left;
    font: var(--tds-headline-05);
    max-width: 100%;
    padding-left: 16px;
}

.vp-shepherd-tour .shepherd-content .shepherd-text {
    font: var(--tds-body-01);
    padding: 16px 16px 0 16px;
    color: var(--scope-grey-958);
}

.vp-shepherd-tour .shepherd-content .shepherd-footer {
    padding: 24px 16px 16px 16px;
    display: flex;
    gap: 10px;
}

.vp-shepherd-tour .shepherd-content .shepherd-footer .shepherd-button {
    transition: none;
    height: 40px;
    padding: 12px;
    border-radius: 4px;
    border: 1px;
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.vp-shepherd-tour .shepherd-content .shepherd-footer .shepherd-button.vp-shepherd_button-primary {
    background-color: #16417f;
    color: white;

    &:hover {
        background-color: #2058a8;
    }

    &:focus {
        background-color: #16417f;
    }
}

.vp-shepherd-tour .shepherd-content .shepherd-footer .shepherd-button.vp-shepherd_button-secondary {
    border: 1px solid rgba(0, 0, 0, 0.48);
    background-color: white;
    color: black;

    &:hover {
        background-color: #2058a8;
        color: white;
    }

    &:focus {
        background-color: white;
        color: black;
    }
}

.shepherd-has-title .shepherd-content .shepherd-header {
    background-color: white !important;
}

.tour-progress-indicator {
    margin-right: auto;
    align-self: center;
    font-family: 'Scania Sans CY Semi Condensed', Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #00000099;

}

.shepherd-element {
    max-width: 600px;
}

@media screen and (max-width: 768px) {
    .shepherd-element {
        max-width: 80%;
    }

    .vp-tour-intro .shepherd-content .shepherd-header {
        display: none;
    }

    .shepherd-text { 
        font-size: 14px;
    }
}

@media screen and (max-width: 390px) {
    .shepherd-element {
        max-width: calc(100% - 32px);
    }

    .shepherd-title {
        max-width: calc(100% - 16px);
    }
}