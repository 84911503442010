.inlineFlexContainer {
  width: auto;
  display: inline-flex;
}

.flexContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.groupsDropdownContainer {
  width: 256px;
  margin-right: 16px;
}

.flexContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.groupsDropdownContainer {
  width: 256px;
  margin-right: 16px;
}

.filtersButtonContainer {
  margin-left: 16px;
  display: flex;
  align-items: center !important;
}

.filterBadge {
  color: #fff !important;
  background-color: var(--tds-blue-400) !important;
  border-radius: 50%;
  padding: 4px;
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 4px;
  position: absolute;
  margin-left: 24px;
  margin-top: -38px;
}

.clearFiltersButton {
  margin-left: 16px;
}

.dahsboardKebabButton {
  margin-left: 16px;
}
