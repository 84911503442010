.headerItemDivider {
    border-right: 1px solid;
    width: 15px;
    height: 20px;
}

.groupsDropdownContainer {
    margin-left: 15px;
    width: 208px;
    margin-bottom: 6px;
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    width: 100%;
}

.onlyTableContainer {
  overflow: auto;
  max-height: calc(100vh - 285px);
}
