.kpiContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.kpiWidgetsContainer {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  padding-bottom: 16px;
}