.tableWidgetHeader {  
  font: var(--tds-headline-05);
  margin-bottom: var(--tds-spacing-element-16);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.tableWidgetActions {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.searchField {
  width: 300px;
}

.searchFieldContainer {
  display: flex;
  gap: 24px;
}

.dividerContainer {
  height: 40px;
  width: 1px;
}

.tableWidgetContainer {
  background-color: var(--tds-white);
  border-radius: 4px;
  margin-bottom: var(--tds-spacing-element-24);
  padding: var(--tds-spacing-element-16);
}

.tableWidget {
  max-height: 500px;
  overflow: auto;
}

.skeletonContent {
  min-height: 100px;
  width: 100%;
}

.tableWidget {
  &::-webkit-scrollbar:horizontal{
    height: 7px;
  }
}

.leftAlignedText {
  text-align: left !important;
}