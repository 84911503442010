.initialRowContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.viewNameContainer {
  min-width: 395px;
}

.searchContainer {
  min-width: 270px;
  padding-top: 24px;
}

.actionButtons {
  width: 100%;
  display: flex;
  gap: var(--tds-spacing-element-16);
}

.rightSideButtons {
  display: flex;
  gap: var(--tds-spacing-element-16);
  margin-left: auto;
}

.buttonSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100px;
}

.selectButtonsContainer {
  display: flex;
  height: 48px;
}

.selectLinkContainer {
  margin-right: 24px;
}

.metricContainerHeader {
  margin-top: 32px;
}

.metricsContainer {
  color: var(--tds-grey-958);
  background-color: var(--tds-grey-50);
  padding: 16px 0;
  margin-top: -2px;
}

.reorderContainer {
  height: 85vh;
}

.draggableTableContainer {
  background-color: var(--tds-grey-50);
  padding: 0 16px;
}

.dndContainer {
  display: flex;
  overflow: auto;
  
  &::-webkit-scrollbar:horizontal{
    height: 7px;
  }
}

.reorderMetricsHeader {
  background-color: var(--tds-grey-50);
  padding: 32px 16px 16px 16px;
  padding-top: 32px;
  padding-bottom: 16px;

  &.smallerPaddingTop {
    padding-top: 16px;
  }

  span {
    margin-left: 8px;
    color: var(--tds-grey-958);
    font: var(--tds-detail-02);
  }
}

.infoIcon {
  color: #2B70D3;
}

.noParamResults {
  font-size: 14px;
  line-height: 16px;
}