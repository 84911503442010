table {
  border-collapse: separate !important;
  border-spacing: 0;
}

.fullMinWidth {
  min-width: 100%;
}

.tableRow {
  display: table-row;
  background-color: var(--tds-table-body-row-background);
  transition: background-color 200ms ease 0s;
  color: var(--tds-table-color);

  td {
    font: var(--tds-detail-02);
    letter-spacing: var(--tds-detail-02-ls);
    display: table-cell;
    box-sizing: border-box;
    color: var(--tds-table-color);
    padding: var(--tds-spacing-element-16);
    vertical-align: center;
    text-align: right;
    border-right: 1px solid var(--tds-table-divider);
    border-bottom: 1px solid var(--tds-table-divider);
  }

  td.driverRowCell {
    padding: var(--tds-spacing-element-8) var(--tds-spacing-element-16);
  }

  td:last-of-type {
    border-right: none;
  }

  td.buttonsRowCell {
    padding-bottom: var(--tds-spacing-element-24);
  }
}

.expandedRow {
  background-color: var(--tds-grey-300) !important;
}

.cellWidth {
  min-width: 124px;
}

.totalRowCell {
  font-weight: bold !important;
  background-color: var(--tds-grey-50) !important;

  &.paddedContent {
    padding-left: 46px;
  }
}

.averageRowCell {
  font-weight: bold !important;
  background-color: var(--tds-grey-100) !important;

  &.paddedContent {
    padding-left: 46px;
  }
}

.leftAlignedText {
  text-align: left !important;
}

.oddRow {
  background-color: var(--tds-grey-100) !important;
}

.evenRow {
  background-color: var(--tds-grey-50) !important;
}

.stickyLeft {
  min-width: 192px;
  position: sticky;
  left: 0;
}

.tableRow .driverNameCell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  transition: background-color 200ms ease 0s;
  color: var(--tds-table-color);
  z-index: 9;
  text-wrap: nowrap;
}

.tableRow:hover {
  .driverNameCell {
    background-color: var(--tds-grey-200) !important;
  }
}

.driverImage {
  height: 34px;
  width: 34px;
  border-radius: 100%;
  overflow: hidden;
  background-color: var(--scope-blue-700);
  color: white;
  line-height: 1em;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vehicleTrackingLink {
  position: sticky;
  left: 16px;
  text-decoration: underline;
}

.driverRow:hover {
    background-color: var(--tds-grey-200) !important;
}

.actionButtonsRow {
  align-items: center;
  display: flex;
  gap: 32px;
}

.exportDriversButton {
  left: 165px;
  position: sticky;
}

.goToDriverTooltip {
  z-index: 3000 !important;
}